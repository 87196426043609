import React from 'react'

const Form = () => {
  return (
    // formsubmit.co allows to protect email adresses by replacing them by a random string
    <form method='post' action='https://formsubmit.co/info@openmembranedatabase.org'>
      <input
        type='checkbox'
        name='agreement'
        style={{ display: 'none' }}
        tabIndex='-1'
        autoComplete='off'
      />
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='name'>
            Name
            <input className='input' type='text' id='name' name='name' required placeholder={'Name'} />
          </label>
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='company'>
            University / Company / Other
            <input className='input' type='text' id='company' name='company' placeholder='University / Company / Other' />
          </label>
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='email'>
            Email
            <input className='input' type='email' id='email' name='email' required placeholder='e-mail' />
          </label>
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='message'>
            Your message
          </label>
          <textarea className='textarea' id='message' name='message' required placeholder='Your message' />
        </div>
      </div>
      <input type="hidden" name="_cc" value="dev@codefathers.be"></input>
      <input type="hidden" name="_subject" value="OMD Website contact form submission"></input>
      <input type="text" name="_honey" style={{display: 'none'}}></input>
      <input type="hidden" name="_autoresponse" value="Thank you for contacting the OMD team"></input>
      <button type='submit' variant='secondary' className='button is-primary has-text-weight-bold is-fullwidth'>
        Submit
      </button>
    </form>
  )
}

export default Form