import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import useOmdDatasetSize from "../components/hooks/use-omd-dataset-size"
import Form from "../components/contact-form"
import Seo from "../components/seo"

const AboutPage = () => {

    return (
        <Layout>
            <Seo title='About the Open Membrane Database' />
            <section className="section">
                <div className="container is-max-desktop">
                    <h1 className="title is-spaced">About the Open Membrane Database</h1>
                    <h2 className="subtitle is-4 mt-6">What is the OMD?</h2>
                    <p className="mb-3">
                        The Open Membrane Database (OMD) is an <b>open-access, crowd-sourced</b> resource with the performance and properties of <b>{useOmdDatasetSize()} reverse osmosis (RO) membranes</b>. The interactive charts and search functions allow easy data exploration and comparison of membrane performance, physicochemical properties and synthesis conditions.
                    </p>
                    <p className="mb-3">
                        <b>Researchers are encouraged to upload their latest membrane performance results</b> (see <Link to='/submit-a-membrane'>Submit a Membrane</Link>) to update the OMD with state-of-the-art membrane technologies. Only data published in peer-reviewed journals, patents, or commercial product datasheets is allowed on the database to provide quality control.
                    </p>
                    <p className="mb-3">
                        Currently, the OMD only contains RO membranes. The goal is to later incorporate other membrane-based separations technologies such as nanofiltration (NF), forward osmosis (FO), and ion-exchange (IX) to name a few. <a href="#contact-us">Please get in contact with us</a> if you would like to contribute to this exciting collaborative effort of sharing and compiling membrane data, or if you have questions or comments!
                    </p>
                    <h2 className="subtitle is-4 mt-6">How to credit the OMD?</h2>
                    <p className="mb-3">
                        If you have used any content of the OMD in your work, please give credit to it by citing the following reference :
                    </p>
                    <blockquote className="mx-3 my-3 is-family-monospace has-background-primary-light px-3 py-3">
                    Ritt, C.L., Stassin, T., Davenport, D.M., DuChanois, R.M., Nulens, I., Yang, Z., Ben-Zvi, A., Segev-Mark, N., Elimelech, M., Tang, C.Y., Ramon, G.Z., Vankelecom, I.F.J., Verbeke, R. (2022). The open membrane database: Synthesis–structure–performance relationships of reverse osmosis membranes. Journal of Membrane Science, 641, 119927
                    </blockquote>
                    <p className="mb-3">
                        The Open Membrane Database (OMD) is licensed under <a href="https://creativecommons.org/licenses/by-nc/4.0/deed.en">CC BY-NC 4.0</a>
                    </p>
                    <h2 className="subtitle is-4 mt-6">
                        People behind the OMD
                    </h2>
                    <h3 className="title is-6 mt-5 is-uppercase has-text-centered-mobile">Founders</h3>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/rhea-verbeke.png"
                                alt="Dr. Rhea Verbeke, co-founder of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Dr. Rhea Verbeke</b> is co-founder of the OMD. She obtained her PhD at KU Leuven in 2020 and conducted research at Yale University, the R&D Department of DuPont Water Solutions and the Research Neutron Source of the TU Munich. She has pioneered the use of epoxide chemistry for TFC membrane synthesis, and explored advanced physico-chemical characterization methods, including ERD and PALS. Her work targets the development of chemically robust water purification membranes for applications in harsh conditions.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/timothee-stassin.jpg"
                                alt="Dr. Timothée Stassin, co-founder of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Dr. Timothée Stassin</b> is co-founder of the OMD. He obtained his PhD at KU Leuven on the vapor-phase processing of metal-organic framework powders and thin films. His current interests as an independent researcher include bridging the gap between high-tech academic research and low-tech field solutions, and the development of collaborative and multidisciplinary research platforms.
                        </div>
                    </div>
                    <h3 className="title is-6 mt-5 is-uppercase has-text-centered-mobile">Founding members</h3>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/ines-nulens.jpg"
                                alt="Ines Nulens, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Ines Nulens</b> obtained a M.S. degree in Bio-Science Engineering (Environmental Technology) from KU Leuven in 2017. In 2018, she joined the Membrane Technology Group to pursue a PhD on the topic of TFC membrane development using ionic liquids and the synthesis-morphology-performance relationship of polyamide TFC membranes.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/douglas-davenport.jpg"
                                alt="Dr. Douglas Davenport, founding team member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Dr. Douglas Davenport</b> earned his PhD in Chemical Engineering from Yale University in 2020 where he studied high-pressure reverse osmosis (HPRO) for hypersaline brine desalination. His PhD combined industrial and theoretical perspectives as he constructed a crossflow apparatus capable of operating at 150 bar in order to study the fundamentals of thin film composite membrane compaction in HPRO. Douglas is continuing his work as a postdoctoral researcher at KU Leuven where he is working to synthesize novel membrane chemistries at pilot scale.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/zhe-yang.jpg"
                                alt="Dr. Zhe Yang, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column has-text-centered">
                            <b>Dr. Zhe Yang</b> a post-doc fellow of Environmental Engineering and received his PhD degree from the University of Hong Kong in 2018. In July of 2016, he was a visiting PhD student in SCELSE (Singapore Centre for Environmental Life Sciences Engineering) Summer Course in Nanyang Technological University. His research interests focus on membrane separation, desalination and water reuse. He was a Guest Editor of Membranes for a special issue on “Membranes for Environmental Application 2020”. He is also an inventor of 2 patents, and the invention of a rapid water filter received a Gold Medal at the 2019 Geneva International Exhibition of Inventions.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/adi-ben-zvi.jpeg"
                                alt="Adi Ben Zvi, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Adi Ben Zvi</b> is a PhD student in Professor Guy Ramon's group at the Technion - Israel Institute of Technology, studying synthesis-morphology-performance relations in polyamide membranes, via the IP reaction. Her research is inspired by halophytic plants: understanding their mechanism of salt separation, and utilizing their biomaterials and morphology to synthesize biomimetic membranes.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/naama-segev.jpg"
                                alt="Naama Segev, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Naama Segev</b> PhD student at the Technion - Israel Institute of Technology in the Faculty of Environmental Engineering under the supervision of Associate Professor Guy Ramon. Her research interests include membrane fouling visualization, oily wastewater membrane based separation, membrane surface interactions and fouling mitigation.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/cody-ritt.jpg"
                                alt="Cody Ritt, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Cody Ritt</b> is a PhD candidate in the Elimelech Research Group, part of the Department of Chemical and Environmental Engineering at Yale University. He received his B.S. and M.S. in Civil Engineering from North Dakota State University (NDSU). His research at Yale has focused on applied nanofluidics, with topics ranging from Monte Carlo simulations of desalination across 2D nanomaterial membranes to experimentally elucidating the mechanisms which govern polyamide ionization.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/ryan-duchanois.jpg"
                                alt="Ryan DuChanois, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Ryan DuChanois</b> is a Ph.D. Candidate in the Department of Chemical and Environmental Engineering at Yale University under the supervision of Professor Menachem (Meny) Elimelech. Ryan received a B.S. in Civil Engineering from the University of Arkansas and was the top graduate in the College of Engineering. Ryan also was a Gates Cambridge Scholar at the University of Cambridge, where he completed an MPhil in Engineering for Sustainable Development and received the Water Conservators Best Dissertation Prize. Now at Yale, Ryan seeks to improve the molecular selectivity of synthetic desalination and ion-selective membranes using principles of biological channels. His Ph.D. focuses specifically on developing membranes that are able to selectively remove a single ionic species from water, such as a contaminant or valuable resource.
                        </div>
                    </div>
                    <h3 className="title is-6 mt-5 is-uppercase has-text-centered-mobile">Professors</h3>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/ivo-vankelecom.jpg"
                                alt="Prof. Ivo Vankelecom, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "entropy" }}
                            />
                        </div>
                        <div className="column">
                            <b>Prof. Ivo Vankelecom</b> studied Bioscience Engineering at the Catholic University of Leuven (KU Leuven), Belgium, where he graduated in 1990. In 1994, he obtained his PhD in Applied Biological Sciences from the Department of Interphase Chemistry on "Inorganic Porous Fillers in Organic Polymer Membranes". He subsequently worked at KU Leuven as postdoc on membrane catalysis. During this period, he spent 6 months at the Ben-Gurion University of the Negev (Beersheva, Israel) in 1999 and at Imperial College (London, England) in 2001. Since 2002, he is professor at KU Leuven where he teaches courses on Membrane Technology, Adsorption, Bio-engineering in Perspective, Environmental Impact Analysis and Chromatography.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/tang-chuyang.jpg"
                                alt="Prof. Tang Chuyang, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Prof. Chuyang Y. Tang</b> is a Professor of Environmental Engineering at the University of Hong Kong. His research focuses on membrane separation technology, desalination, and water reuse. His invention on aquaporin-based biomimetic membranes has resulted in the successful commercialization of Aquaporin Inside Membranes. Professor Tang is an Editorial/Advisory Board Member of Environmental Science &amp; Technology Letters, Journal of Membrane Science, Desalination, and Environmental Research. He has over 260 refereed publications, with a Google Scholar citation of &gt;22,000 and an H-index of 84. He is a recipient of Hong Kong RGC Senior Research Fellowship, Singapore Ministry of National Development R&amp;D Merit Award, Finland Distinguished Professor Program Fellowship, and the International Desalination Association Fellowship.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/guy-ramon.jpg"
                                alt="Prof. Guy Ramon, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "attention" }}
                            />
                        </div>
                        <div className="column">
                            <b>Prof. Guy Ramon</b> received his doctoral degree in Environmental Engineering from Technion - Israel Institute of Technology at Haifa and then spent one year at the University of California, Los Angeles as a US-Israel BARD postdoctoral fellow, followed by 2 years at Princeton University as a Marie-Curie fellow. Since 2013 he has been at the department of Civil & Environmental Engineering at the Technion, and currently is an Associate Professor, head of the Environmental Engineering undergraduate program and the Water & Energy Technologies Laboratory (WETLab). Research activities in his group are focused on developing sustainable technologies for water and energy production, and environmental concerns at the Energy-Water Nexus. These include broad aspects of membrane science and technology, fluid dynamics, and development of acoustic energy conversion devices.
                        </div>
                    </div>
                    <div className="columns mb-6">
                        <div className="column is-3 has-text-centered">
                            <StaticImage
                                src="../images/team/menachem-elimelech.jpg"
                                alt="Prof. Menachem Elimelech, founding member of the Open Membrane Database"
                                width={250}
                                aspectRatio={1}
                                transformOptions={{ cropFocus: "entropy" }}
                            />
                        </div>
                        <div className="column">
                            <b>Prof. Menachem Elimelech</b> graduated summa cum laude from the Hebrew University of Jerusalem with B.S. and M.S. degrees in 1983 and 1985, respectively. He earned his Ph.D. in environmental engineering at Johns Hopkins University in 1989 under the direction of Charles R. O’Melia. His dissertation was titled “The Effect of Particles Size on the Kinetics of Deposition of Brownian Particles in Porous Media.” Elimelech was professor and vice chair of the Department of Civil and Environmental Engineering at UCLA. He moved to Yale University in 1998, he founded Yale's Environmental Engineering Program and is the current program director as well as Roberto C. Goizueta professor. His research interests include problems involving physicochemical, colloidal, and microbial processes in engineered and natural environmental systems.
                        </div>
                    </div>
                    <h2 className="subtitle is-4 mt-6">Publications related to or citing the OMD</h2>
                    <blockquote className="mx-3 my-3 is-family-monospace has-background-primary-light">
                        Yang, Z., Guo, H., &amp; Tang, C. Y. (2019). The upper bound of thin-film composite (TFC) polyamide membranes for desalination. <i>Journal of Membrane Science, 590</i>, 117297. [DOI:&nbsp;<a href="https://dx.doi.org/10.1016/j.memsci.2019.117297">10.1016/j.memsci.2019.117297</a>]
                    </blockquote>
                    <div className="columns my-6 is-multiline is-mobile">
                        <div className="column px-5 py-5 is-6-mobile is-3-tablet is-flex is-align-items-center">
                            <div><StaticImage width={250} src="../images/logos/fwo.jpg" alt="FWO supports the Open Membrane Database" /></div>
                        </div>
                        <div className="column px-5 py-5 is-6-mobile is-3-tablet is-flex is-align-items-center">
                            <div><StaticImage width={250} src="../images/logos/kuleuven.png" alt="KULeuven supports the Open Membrane Database" /></div>
                        </div>
                        <div className="column px-5 py-5 is-6-mobile is-3-tablet is-flex is-align-items-center">
                            <div><StaticImage width={250} src="../images/logos/nsf.png" alt="NSF supports the Open Membrane Database" /></div>
                        </div>
                        <div className="column px-5 py-5 is-6-mobile is-3-tablet is-flex is-align-items-center">
                            <div><StaticImage width={250} src="../images/logos/technion.png" alt="Technion supports the Open Membrane Database" /></div>
                        </div>
                        <div className="column px-5 py-5 is-6-mobile is-3-tablet is-flex is-align-items-center">
                            <div><StaticImage width={250} src="../images/logos/university-of-hong-kong.png" alt="University of Hong-Kong supports the Open Membrane Database" /></div>
                        </div>
                        <div className="column px-5 py-5 is-6-mobile is-3-tablet is-flex is-align-items-center">
                            <div><StaticImage width={250} src="../images/logos/yale.png" alt="Yale University supports the Open Membrane Database" /></div>
                        </div>
                        <div className="column px-5 py-5 is-6-mobile is-3-tablet is-flex is-align-items-center">
                            <div><StaticImage width={250} src="../images/logos/OMD-open-membrane-database-african-membrane-society-AMSIC.jpg" alt="The African Membrane Society (AMSIC) supports the Open Membrane Database" /></div>
                        </div>
                        <div className="column px-5 py-5 is-6-mobile is-3-tablet is-flex is-align-items-center">
                            <div><StaticImage width={250} src="../images/logos/OMD-open-membrane-database-membrane-society-of-australasia-MSA.jpg" alt="The Membrane Society of Australasia (MSA) supports the Open Membrane Database" /></div>
                        </div>
                    </div>
                    <div className="box">
                        <h2 className="subtitle is-4 has-text-centered">
                            Contact the OMD Team
                        </h2>
                        <div className="columns is-centered">
                            <div className="column is-6">
                                <p className="mb-3 has-text-centered"><b>info@openmembranedatabase.org</b> </p>
                                <p className="has-text-centered has-text-grey">- OR -</p>
                                <Form />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default AboutPage
